import React from 'react';
import './style.css';
import FeatImage01 from './images/aboutus.png';
import FeatImage02 from './images/uiux3.png';
import FeatImage03 from './images/software.png';

export default function AboutUsSection() {
  return (
    <section>
      <div className="container">
        <div className="py-5 border-top border-dark">

          {/* Section header */}
          <div className="text-center pb-5 mb-5">
            <div className="badge bg-success text-wrap mb-3">Reach goals that matter</div>
            <h1 className="h2 mb-4">One Platform , Unlimited Solutions</h1>
            <p className="text-muted fs-5">
            Our MoreSlack empowers your team to achieve key objectives with a suite of integrated tools. From tracking Git commits and chat activity to automating invoices and managing users, everything is centralized in one powerful platform. Simplify your operations, optimize efficiency, and unlock the potential to tackle any challenge—all in one place.
            </p>
          </div>

          {/* Items */}
          <div className="row gy-5">

            {/* 1st item */}
            <div className="row align-items-center mt-5 mb-5 flex-row-reverse">
              {/* Image */}
              <div className="col-lg-6 mb-4 mb-lg-0 order-lg-1" data-aos="fade-up">
                <img className="img-fluid" src={FeatImage01} alt="Features 01" />
              </div>
              {/* Content */}
              <div className="col-lg-6" data-aos="fade-right">
                <div className="ps-lg-5">
                  <div className="h3 font-italic text-primary mb-2">Drive Success with Real-Time Insights</div>
                  {/* <h3 className="h3 mb-3">Keep projects on schedule</h3> */}
                  <p className="text-muted fs-5 mb-4 text-justify">
                    Unlock the full potential of your team with instant access to critical data. Our MoreSlack delivers actionable insights on Git commits, chat activity, and work hours, empowering your team to stay productive and on schedule. Transform your workflow and elevate your business performance today.
                  </p>
                  <ul className="list-unstyled fs-5">
                    <li className="d-flex align-items-center mb-2">
                      <svg className="bi bi-check-circle text-success me-2" width="1em" height="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 0-1.08-1.04L7 7.293 5.53 5.823a.75.75 0 1 0-1.06 1.06l2 2a.75.75 0 0 0 1.08 0l4-4z"/>
                        <path d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0zm-1.5 0a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0z"/>
                      </svg>
                      <span>Streamline Development with Git Commit Tracking</span>
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <svg className="bi bi-check-circle text-success me-2" width="1em" height="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 0-1.08-1.04L7 7.293 5.53 5.823a.75.75 0 1 0-1.06 1.06l2 2a.75.75 0 0 0 1.08 0l4-4z"/>
                        <path d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0zm-1.5 0a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0z"/>
                      </svg>
                      <span>Enhance Collaboration with Chat Activity Monitoring</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <svg className="bi bi-check-circle text-success me-2" width="1em" height="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 0-1.08-1.04L7 7.293 5.53 5.823a.75.75 0 1 0-1.06 1.06l2 2a.75.75 0 0 0 1.08 0l4-4z"/>
                        <path d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0zm-1.5 0a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0z"/>
                      </svg>
                      <span>Optimize Time Management with Work Hours Analytics</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 2nd item */}
            <div className="row align-items-center flex-row-reverse mb-5 mt-5">
              {/* Image */}
              <div className="col-lg-6 mb-4 mb-lg-0" data-aos="fade-up">
                <img className="img-fluid" src={FeatImage02} alt="Features 02" />
              </div>
              {/* Content */}
              <div className="col-lg-6" data-aos="fade-left">
                <div className="pe-lg-5">
                  <div className="h3 font-italic text-primary mb-2">Automate Billing. Focus on Growth.</div>
                  {/* <h3 className="h3 mb-3">Keep projects on schedule</h3> */}
                  <p className="text-muted fs-5 mb-4 text-justify">
                    Simplify your invoicing process with automated invoice generation. Our MoreSlack converts tracked work hours into professional invoices with ease, saving you time and reducing administrative overhead. Elevate your business operations and focus on what really matters—growing your business.
                  </p>
                  <ul className="list-unstyled fs-5">
                    <li className="d-flex align-items-center mb-2">
                      <svg className="bi bi-check-circle text-success me-2" width="1em" height="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 0-1.08-1.04L7 7.293 5.53 5.823a.75.75 0 1 0-1.06 1.06l2 2a.75.75 0 0 0 1.08 0l4-4z"/>
                        <path d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0zm-1.5 0a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0z"/>
                      </svg>
                      <span>Effortless Invoice Generation</span>
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <svg className="bi bi-check-circle text-success me-2" width="1em" height="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 0-1.08-1.04L7 7.293 5.53 5.823a.75.75 0 1 0-1.06 1.06l2 2a.75.75 0 0 0 1.08 0l4-4z"/>
                        <path d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0zm-1.5 0a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0z"/>
                      </svg>
                      <span>Maximize Your Efficiency, Customizable and Professional </span>
                    </li>
                    <li className="d-flex align-items-center">
                      <svg className="bi bi-check-circle text-success me-2" width="1em" height="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 0-1.08-1.04L7 7.293 5.53 5.823a.75.75 0 1 0-1.06 1.06l2 2a.75.75 0 0 0 1.08 0l4-4z"/>
                        <path d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0zm-1.5 0a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0z"/>
                      </svg>
                      <span>Seamless Integration with Work Hours Tracking</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 3rd item */}
            <div className="row align-items-center mt-5 mb-5">
              {/* Image */}
              <div className="col-lg-6 mb-4 mb-lg-0" data-aos="fade-up">
                <img className="img-fluid" src={FeatImage03} alt="Features 03" />
              </div>
              {/* Content */}
              <div className="col-lg-6" data-aos="fade-right">
                <div className="ps-lg-5">
                  <div className="h3 font-italic text-primary mb-2">Manage Everything in One Place</div>
                  {/* <h3 className="h3 mb-3">Keep projects on schedule</h3> */}
                  <p className="text-muted fs-5 mb-4 text-justify">
                  Take control of your operations with a powerful admin dashboard. Our admin page centralizes user management, invoice histories, charts, and company information, giving you complete oversight at your fingertips. Streamline your workflow and make data-driven decisions with ease.
                  </p>
                  <ul className="list-unstyled fs-5">
                    <li className="d-flex align-items-center">
                      <svg className="bi bi-check-circle text-success me-2" width="1em" height="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 0-1.08-1.04L7 7.293 5.53 5.823a.75.75 0 1 0-1.06 1.06l2 2a.75.75 0 0 0 1.08 0l4-4z"/>
                        <path d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0zm-1.5 0a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0z"/>
                      </svg>
                      <span>Centralized Company Information</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <svg className="bi bi-check-circle text-success me-2" width="1em" height="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 0-1.08-1.04L7 7.293 5.53 5.823a.75.75 0 1 0-1.06 1.06l2 2a.75.75 0 0 0 1.08 0l4-4z"/>
                        <path d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0zm-1.5 0a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0z"/>
                      </svg>
                      <span>All Your Data, Visualized</span>
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <svg className="bi bi-check-circle text-success me-2" width="1em" height="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 0-1.08-1.04L7 7.293 5.53 5.823a.75.75 0 1 0-1.06 1.06l2 2a.75.75 0 0 0 1.08 0l4-4z"/>
                        <path d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0zm-1.5 0a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0z"/>
                      </svg>
                      <span>Comprehensive User Management</span>
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <svg className="bi bi-check-circle text-success me-2" width="1em" height="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 0-1.08-1.04L7 7.293 5.53 5.823a.75.75 0 1 0-1.06 1.06l2 2a.75.75 0 0 0 1.08 0l4-4z"/>
                        <path d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0zm-1.5 0a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0z"/>
                      </svg>
                      <span>Instant Access to Invoice Histories</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}
