import React, { useState } from 'react';
import config from 'config'
import NotificationAlert from "react-notification-alert";

export default function ContactUs() {
  const notificationAlert = React.useRef();
 
  const notify = (place, msg, color) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {msg}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear the error as the user types
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.first_name.trim()) {
      newErrors.first_name = 'First name is required';
    }
    if (!formData.last_name.trim()) {
      newErrors.last_name = 'Last name is required';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email address is invalid';
    }
    if (!formData.subject.trim()) {
      newErrors.subject = 'Subject is required';
    }
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
        try {
           const baseUrl = config.baseUrl;
            const response = await fetch(`${baseUrl}/contact`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(formData),
            });
        
            if (response.ok) {
              notify("tr", "Your message has been sent successfully.", 2)
            } else {
              console.error('Error submitting form');
            }
          } catch (error) {
            console.error('Error:', error);
        }
    }
  };

  return (
    <div className="footer-contact">
      <NotificationAlert ref={notificationAlert} />
      <div className="contact-container" id="contact">
        <div className="contact-bg"></div>
        <div className="relative">
          <div className="relative contact-title">
            <div className="contact-text-center">
              <div className="t-48" style={{ opacity: 1 }}>
                <h4 className="text-content-heading">Contact US</h4>
              </div>

              <div className="sub-title" style={{ opacity: 1 }}>
                <p>If you have any problems with MoreSlack, please contact us!</p>
              </div>

            </div>
          </div>
        </div>

        <div className="relative contact-form">
          <form onSubmit={handleSubmit} className="php-email-form">
            <div className="left-form">
              <div className="w-full">
                <div className="w-full flex flex-auto flex-col items-start">
                  <div className="relative w-full">
                    <input
                      placeholder="First Name"
                      className="contact-input w-full"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="w-full mt-15">
                <div className="w-full flex flex-auto flex-col items-start">
                  <div className="relative w-full">
                    <input
                      placeholder="Last Name"
                      className="contact-input w-full"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="w-full mt-15">
                <div className="w-full flex flex-auto flex-col items-start">
                  <div className="relative w-full">
                    <input
                      placeholder="Email"
                      type="email"
                      className="contact-input w-full"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="right-form">
              <div className="w-full">
                <div className="w-full flex flex-auto flex-col items-start">
                  <div className="relative w-full">
                    <input
                      placeholder="Subject"
                      className="contact-input w-full"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="w-full mt-15">
                <div className="w-full flex flex-auto flex-col items-start">
                  <div className="relative w-full">
                    <textarea
                      placeholder="Message"
                      className="contact-input w-full"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="w-full mt-15 button-align-right">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
                <button type="submit" className="send-btn">Send Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
