// src/config/index.js

var config = {
  env: 'production',
  baseUrl: 'https://moreslack.ink',
  redirectUri: 'https://moreslack.ink/slack/oauth_redirect',
  slackClientId: '6058716583493.6971495671286',
  slackClientSecret: 'e2704d24df5806c01e27a9ce0cc36e5b',
}

export default config;