import React, { useState } from 'react';
import Footer from 'components/footer/footer.js';
import Header from 'components/header/header.js';
import ContactUs from 'components/ContactUS';

export default function Support() {

    return (
      <div>
        <Header />
        <ContactUs />
        <Footer />
      </div>
    );
  }
  