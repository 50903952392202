import React, { useState, useEffect, useRef } from 'react';
import { Container, NavbarBrand, Navbar, Nav, NavItem, NavbarToggler, Collapse } from 'reactstrap';
import slackIcon from '../../assets/images/logos/slack.jpg';
import { Link } from "react-router-dom";
import './header.css'; 

import logo from '../../assets/images/logos/white-text.png';
import birdLogo from '../../assets/images/logos/bird-logo.png';
import config from '../../config'

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useDispatch } from 'react-redux';
import actions from '../../actions';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';

import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AnimateButton from 'components/@extended';
// material-ui
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MDBox from 'components/MDBox';
import NotificationAlert from "react-notification-alert";

const baseUrl = config.baseUrl;

const Header = ({ isTop = true, data, isRequesting, isRequestingFailed }) =>{

  const notificationAlert = React.useRef();
  const notify = (place, msg, color) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {msg}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  let initClassName = 'header6';
  if(!isTop) initClassName = 'header6 inner-header';

  let initPricingClassName = 'link-style-button';

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [teamName, setTeamName] = useState(null);
  const [headerClassNames, setHeaderClassNames] = useState(initClassName);
  const [pricingClassName, setPricingClassName] = useState(initPricingClassName);

  const [isMobileBar, setMobileBar] = useState(false);

  const dispatch = useDispatch();

  // Function to check window scroll position
  const checkScroll = () => {
    if (window.scrollY === 0) {
      setHeaderClassNames(initClassName);
      setPricingClassName(initPricingClassName);
    } else {
      if(isTop){
        setHeaderClassNames('header6 header-banner');
        setPricingClassName('link-style-button-black');
      }
      else{
        setHeaderClassNames('header6 header-banner inner-header');
        setPricingClassName('link-style-button-black inner-header');
      }
    }
  }

  useEffect(() => {
    
    const currentTime = new Date().getTime();
    const expiryTime = localStorage.getItem('expiryTime');

    if (expiryTime && currentTime > parseInt(expiryTime)) {
      handleSignout();
    }

    const token = localStorage.getItem('token');
    const team_name = localStorage.getItem('team_name');

    // Check on initial load
    checkScroll();

    if (token) {
      setIsLoggedIn(true);
      localStorage.setItem('isLoggedIn', true);
      setTeamName(team_name);
    }
    // Add event listener to window scroll
    window.addEventListener('scroll', checkScroll);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('scroll', checkScroll);

  }, [])

  const handleSignout = () => {
    setIsLoggedIn(false)
    localStorage.setItem('isLoggedIn', false);

    localStorage.removeItem('token');
    localStorage.removeItem('team_name');
    localStorage.removeItem('team_id');
    localStorage.removeItem('ws_id');
    localStorage.removeItem('expiryTime');
    localStorage.removeItem('subscription_flag');
    navigate('/');
    
  };

  const toggle = () => setIsOpen(!isOpen);

  const buttonStyle = {
    alignItems: 'center',
    color: '#000',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '100px',
    display: 'inline-flex',
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    fontWeight: '600',
    height: '48px',
    justifyContent: 'center',
    textDecoration: 'none',
    width: 'auto',
    padding: '5px 30px',
    marginTop: '0px'
  };

  const svgStyle = {
    height: '20px',
    width: '20px',
    marginRight: '12px'
  };
  
  const token = localStorage.getItem('token');
  const subscription_flag = localStorage.getItem('subscription_flag');
  const team_id = localStorage.getItem('team_id');
  let s_flag;
  if(subscription_flag === 'true'){
    s_flag = true;
  }else{
    s_flag = false;
  }
  
  useEffect(() => {
    if(data) {
      const { token, team_name, team_id, ws_id, subscription_flag, is_admin } = data;

      // Check if user is admin or not
      if(is_admin) {
        // Store the token in localStorage or in-memory storage as per your security policy
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('token', token);
        localStorage.setItem('team_name', team_name);
        localStorage.setItem('team_id', team_id);
        localStorage.setItem('ws_id', ws_id);
        localStorage.setItem('subscription_flag', subscription_flag);
        // Also store the current timestamp
        const expiryTime = new Date().getTime() + (60 * 60 * 1000); // Current time plus 1 hour (in milliseconds)
        localStorage.setItem('expiryTime', expiryTime.toString());
        navigate('/'); 
        window.location.reload(); 
      } else {
        let timeoutId;
        setErrorMessage('You are not owner of current workspace. You have no permission to signin.');
        timeoutId = setTimeout(() => {
          navigate('/landing');
        }, 3000);
        // Cleanup function to clear the timeout
        return () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        };
      }
    }
  }, [data, navigate]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [slackWorkspace, setSlackWorkspace] = useState("")
  const [slackMail, setSlackMail] = useState("")
  const [slackVerificationCode, setSlackVerificationCode] = useState("")

  const validateEmail = (email) => {
    // Regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendVerificationCode = (e) => {
    e.preventDefault()
    if(slackWorkspace == "") {
      notify("tr", "Please fill out the Slack WorkSpace", 3)
      return;
    }
    if(slackMail == "") {
      notify("tr", "Please fill out the Slack Mail", 3)
      return;
    }
    if(validateEmail(slackMail) == false) {
      notify("tr", "Please fill out the Slack Mail correctly", 3)
      return;
    }
    const data = new FormData();
    data.append('slack_workspace', slackWorkspace);
    data.append('email_address', slackMail);

    fetch(`${config.baseUrl}/send_code`, {
      method: 'POST',
      body: data,
    })
      .then((response) => response.json())
      .then((result) => {
        if(result['status'] == 'OK')
          notify("tr", "Sent the slack verification code successfully.", 5)
        else
          notify("tr", result['message'], 3)
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const siggin = (e) => {
    e.preventDefault()
    if(slackWorkspace == "") {
      notify("tr", "Please fill out the Slack WorkSpace", 3)
      return;
    }
    if(slackMail == "") {
      notify("tr", "Please fill out the Slack Mail", 3)
      return;
    }
    if(validateEmail(slackMail) == false) {
      notify("tr", "Please fill out the Slack Mail correctly", 3)
      return;
    }
    if(slackVerificationCode == ""){
      notify("tr", "Please fill out the verification code", 3)
      return;
    }
    const data = new FormData();
    data.append('slack_workspace', slackWorkspace);
    data.append('email_address', slackMail);
    data.append('email_code', slackVerificationCode);


    fetch(`${config.baseUrl}/sign_in`, {
      method: 'POST',
      body: data,
    })
      .then((response) => response.json())
      .then((result) => {
        if(result['status'] == 'ERROR')
          notify("tr", result['message'], 3)
        else{
            dispatch(actions.getTokenCompleted(result))
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="topbar" id="top">
      <div 
        className={headerClassNames} 
        style={{
          fontFamily: 'Lato, sans-serif',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100
        }}
      >
        
        <Container className="po-relative" style={{maxWidth: '95%'}}>
          <div className="navbar-expand-lg h6-nav-bar d-flex" style={{display: 'flex', justifyContent: 'space-between', maxHeight: '80px'}}>
            <NavbarBrand href="/" style={{
              padding: '0px'
            }}>
              <Box
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center'
                }}
              >
                <img 
                  src={birdLogo} 
                  style={{
                    width: '60px'
                  }}
                  alt="wrapkit" />
                <span 
                  className="nav-link"
                  style={{
                    padding: '0px',
                    color: 'white'
                  }}
                >MoreSlack</span>
              </Box>

            </NavbarBrand>

            <Collapse isOpen={isOpen} navbar 
              className={
                isTop ? "hover-dropdown menu-bar  font-14 justify-content-end":
                "hover-dropdown menu-bar  font-14 justify-content-between"
              }
              id="h6-info" 
              style={{
                display: 'flex', 
                justifyContent: 'space-between',
                marginRight: isTop ? '0px': '50px',
                marginLeft: isTop ? '0px': '100px'
              }}
            >
              <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                  component: 'form',
                  onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    const email = formJson.email;
                    handleClose();
                  },
                }}
              >
                <DialogTitle>Sign In</DialogTitle>
                <DialogContent>
                  <NotificationAlert ref={notificationAlert} />
                  <div style={{
                    width: '450px',
                    padding: '10px'
                  }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="slack_workspace">Slack WorkSpace</InputLabel>
                          <MDBox sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px'
                          }}>
                            <OutlinedInput
                              id="slack_workspace"
                              type="text"
                              value={slackWorkspace}
                              name="slack_workspace"
                              // onBlur={handleBlur}
                              onChange={e => setSlackWorkspace(e.target.value)}
                              placeholder="Enter workspace"
                              fullWidth
                              // error={Boolean(touched.email && errors.email)}
                              sx={{
                                '& .MuiOutlinedInput-input': {
                                  padding: '10px', // Adjust input text padding,
                                  height: '20px',
                                  textAlign: 'right'
                                },
                                width: "calc(100% - 115px)"
                              }}
                            />
                            <span style={{marginLeft: "5px"}}>.slack.com</span>
                          </MDBox>
                        </Stack>
                     
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="email_address">Email Address</InputLabel>
                          <MDBox sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px'
                          }}>
                            <OutlinedInput
                              id="email_address"
                              type="email"
                              value={slackMail}
                              name="email_address"
                              onChange={e => setSlackMail(e.target.value)}
                              placeholder="Enter email address"
                              fullWidth
                              sx={{
                                '& .MuiOutlinedInput-input': {
                                  padding: '10px', // Adjust input text padding,
                                  height: '20px'
                                },
                              }}
                            />
                            <span style={{ height: "40px"}}>
                              <Button 
                              disableElevation 
                              fullWidth size="md" type="submit" variant="contained" color="primary"
                              style={{
                                color: "white",
                                width: "100px",
                                height: "40px",
                                padding: "10px",
                                lineHeight: "20px"
                              }}
                              onClick={sendVerificationCode}
                            >
                              Send Code
                            </Button>
                            </span>
                          </MDBox>
                          
                        </Stack>
                       
                      </Grid>
                      
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="email_code">SMS code</InputLabel>
                          <MDBox sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px'
                          }}>
                            
                            <OutlinedInput
                              id="email_code"
                              type="text"
                              value={slackVerificationCode}
                              name="email_code"
                              onChange={e => setSlackVerificationCode(e.target.value)}
                              placeholder="Enter Code"
                              fullWidth
                              sx={{
                                '& .MuiOutlinedInput-input': {
                                  padding: '10px', // Adjust input text padding,
                                  height: '20px',
                                  
                                },
                              }}
                            />
                            
                          </MDBox>
                        </Stack>
                      
                      </Grid>
                    
                      <Grid item xs={12} sx={{
                        marginTop: "10px"
                      }}>
                        <div style={{
                          width: "60%",
                          margin: "auto"
                        }}>
                          <AnimateButton>
                            <Button 
                              disableElevation 
                              fullWidth size="md" type="submit" variant="contained" color="primary"
                              style={{
                                color: "white"
                              }}
                              onClick={siggin}
                            >
                              Sign In
                            </Button>
                          </AnimateButton>
                        </div>
                      </Grid>
                    </Grid>
                    <Typography style={{
                      fontSize: "15px",
                      textAlign: 'justify',
                      marginTop: '15px'
                      }} >
                      If you have not added the MoreSlack on your workspace, please click &quot;Add to slack&quot; to get started.
                      </Typography>
                  </div>
                </DialogContent>
              </Dialog>

              <Nav navbar className="ms-auto" style={{display: 'flex', justifyContent: 'space-between'}}>
                <NavItem style={{marginRight: '15px'}}>
                  <Link 
                    className="nav-link" 
                    to={"/"}
                    style={{
                      color: 'white',
                      fontFamily: 'Lato, sans-serif',
                      fontSize: '16px',
                    }}
                  >
                    {!isTop && <>
                     Home
                    </>}
                  </Link>
                </NavItem>
                {(token && isTop) &&
                  <NavItem style={{marginRight: '15px'}}>
                    <Link 
                      className="nav-link" 
                      to={"/manage/company"}
                      style={{
                        color: 'white',
                        fontFamily: 'Lato, sans-serif',
                        fontSize: '16px'
                      }}
                    >Company</Link>
                  </NavItem>
                }
                
                {(token && isTop) &&
                  <NavItem style={{marginRight: '15px'}}>
                    <Link 
                      className="nav-link" 
                      to={"/manage/chart"}
                      style={{
                        color: 'white',
                        fontFamily: 'Lato, sans-serif',
                        fontSize: '16px'
                      }}
                    >Chart</Link>
                  </NavItem>
                }
                {(token && isTop) &&
                  <NavItem style={{marginRight: '15px'}}>
                    <Link 
                      className="nav-link" 
                      to={"/manage/users"}
                      style={{
                        color: 'white',
                        fontFamily: 'Lato, sans-serif',
                        fontSize: '16px'
                      }}
                      >User</Link>
                  </NavItem>
                }
                {(token && isTop) &&
                  <NavItem style={{marginRight: '15px'}}>
                    <Link 
                      className="nav-link" 
                      to={"/manage/invoice_history"}
                      style={{
                        color: 'white',
                        fontFamily: 'Lato, sans-serif',
                        fontSize: '16px'
                      }}
                      >Invoice</Link>
                  </NavItem>
                }
                {(token && isTop && !s_flag) &&
                <NavItem style={{marginRight: '15px'}}>
                  <Link 
                    className="nav-link" 
                    to={"/manage/pricing"}
                    style={{
                      color: 'white',
                      fontFamily: 'Lato, sans-serif',
                      fontSize: '16px'
                    }}
                  >Pricing</Link>
                </NavItem>
                }
                {token && s_flag && isTop &&
                <NavItem style={{marginRight: '15px'}}>
                  <form action={`${baseUrl}/billing-portal`} method="POST">
                          <input type="hidden" name="team_id" value={team_id}/>
                          <button className={pricingClassName}  type="submit">Pricing</button>
                  </form>
                </NavItem>
                }
                {(token && isTop) &&
                  <NavItem style={{marginRight: '15px'}}>
                    <Link 
                      className="nav-link" 
                      to="#"
                      style={{
                        color: 'white',
                        fontFamily: 'Lato, sans-serif',
                        fontSize: '16px'
                      }}
                      onClick={(e) => {
                        e.preventDefault(); // Prevents default link behavior
                        scrollToContact();
                      }}
                      >Contact</Link>
                  </NavItem>
                }
              
              </Nav>
              {isLoggedIn ?
              (
                <div className="team-button" style={{ display: 'flex'}}>
                  <span className="team-name" style={{ color: 'white', fontWeight: 'bold', marginRight: '10px', fontSize: '16px' }}>
                    {teamName}
                  </span>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="logout"
                    sx={{ }}
                    onClick={handleSignout}
                    style={{
                      width: 'auto',
                      color: 'white',
                      margin: '0px',
                      padding: '0px'
                    }}
                  >
                    <LogoutIcon />
                  </IconButton>
                </div>
              )
              :
              (
              <button
                className="slack-sign"
                style={{
                  height: '36px',
                  display: 'flex',
                  alignItems: 'center',
                  width: 'auto',
                  backgroundColor: 'white',
                  color: '#57585A',
                  padding: '15px',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  borderRadius: '100px',
                  marginTop: '0px',
                  marginLeft: '15px'
                }}
                onClick={handleClickOpen}
              >                
                Sign In
              </button>
              )}
            </Collapse>

            <div className="mobile-menu" aria-hidden="true" onClick={() => setMobileBar(true)}> 
              <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ }}
            >
              <MenuIcon />
              </IconButton>
            </div>
          </div>
        </Container>
      </div>

      <div className={`mm-ocd  ${isMobileBar ? 'mm-ocd--open': ''}`}>
        <div className="mm-ocd__content">
          <div className="mobile-menu--container mm-spn mm-spn--light mm-spn--navbar" data-mm-spn-title="">
            <div className="action mobile-close" onClick={() => setMobileBar(false)}>
              <button>X</button>
            </div>
            <nav className="nav navigation">
              <ul id="mobile-menu-3" className="mobile-main mm-spn--open">
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1941">
                  <a href="/">
                    {!isTop && <>
                     Home
                    </>}
                  </a>
                </li>
                {(token && isTop) && 
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1941">
                    <a href="/manage/company">Company</a>
                  </li>
                }
                {(token && isTop) && 
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1941">
                    <a href="/manage/chart">Chart</a>
                  </li>
                }
                {(token && isTop) && 
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1941">
                    <a href="/manage/users">User</a>
                  </li>
                }
                {(token && isTop) && 
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1941">
                    <a href="/manage/invoice_history">Invoice</a>
                  </li>
                }
                {(token && isTop && !s_flag) && 
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1941">
                    <a href="/manage/pricing">Pricing</a>
                  </li>
                }
                {(token && isTop) && 
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1941">
                    <a href="#contact">Contact</a>
                  </li>
                }

                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1941" style={{
                  marginTop: '100px'
                }}>
                  {isLoggedIn ?
                  (
                    <div>
                      <span style={{ color: 'white', fontWeight: 'bold', marginBottom: '0px', fontSize: '16px', color: 'black' }}>
                        {teamName}
                      </span>

                      <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="logout"
                        sx={{ }}
                        onClick={handleSignout}
                      >
                        <LogoutIcon />
                      </IconButton>

                    </div>
                  ):(

                  <a  onClick={handleClickOpen} style={{
                    textAlign: 'center'
                  }}>
                      <button
                        className="slack-sign"
                        style={{
                          height: '36px',
                          display: 'flex',
                          alignItems: 'center',
                          width: 'auto',
                          border: '1px solid black',
                          backgroundColor: 'white',
                          color: '#57585A',
                          padding: '15px',
                          fontFamily: 'Lato',
                          fontSize: '16px',
                          borderRadius: '100px',
                          marginTop: '0px',
                          marginLeft: 'auto',
                          marginRight: 'auto'
                        }}
                        onClick={handleClickOpen}
                      >
                        Sign in
                      </button>
                  </a>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </div>
       <div className="mm-ocd__backdrop"  onClick={() => setMobileBar(false)}>
       </div>
      </div>
    </div>
  );

}
const mapStateToProps = (state) => ({
  data: state.tokens.data,
  isRequesting: state.tokens.isRequesting,
  isRequestingFailed: state.tokens.isRequestingFailed
});

Header.propTypes = {
  isTop: PropTypes.bool,
  data: PropTypes.any,
  isRequesting: PropTypes.bool,
  isRequestingFailed: PropTypes.bool,
};

export default connect(mapStateToProps)(Header);
